import tcPlugin from "../plugins/tcPlugin";

const MsgServer = {
  success: (msg: string) => {
    tcPlugin.VsNotification({
      title: "一切顺利",
      text: msg,
      color: "rgb(44,150,120)", // 青矾绿
      position: "top-right",
      progressAuto: true,
      duration: 6000,
      sticky: true,
      icon: "<i class='bx bx-select-multiple' ></i>",
    });
  },
  warn: (msg: string) => {
    tcPlugin.VsNotification({
      title: "有点毛病",
      text: msg,
      color: "rgb(255,166,15)", // 鸭掌黄
      position: "top-right",
      progressAuto: true,
      duration: 6000,
      sticky: true,
      icon: "<i class='bx bx-error' ></i>",
    });
  },
  error: (msg: string) => {
    tcPlugin.VsNotification({
      title: "问题很大",
      text: msg,
      color: "rgb(207,72,19)", // 落霞红
      position: "top-right",
      progressAuto: true,
      duration: 6000,
      sticky: true,
      icon: "<i class='bx bxs-bug'></i>",
    });
  },
  normal: (title: string, msg: string) => {
    tcPlugin.VsNotification({
      title: title,
      text: msg,
      color: "#61649f",
      position: "bottom-right",
      progressAuto: true,
      duration: 6000,
      sticky: true,
      icon: "<i class='bx bx-bell' ></i>",
    });
  },
  chat: () => {
    tcPlugin.VsNotification({
      title: "消息通知",
      color: "#61649f",
      position: "bottom-right",
      progressAuto: true,
      duration: 12000,
      sticky: true,
      icon: "<i class='bx bx-bell' ></i>",
      content: "性感荷官在线约聊",
    });
  },
};

export default MsgServer;
