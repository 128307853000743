<template>
  <vs-sidebar
    class="market-sidebar"
    :class="isDark ? 'header-navbar-dark' : 'header-navbar'"
    absolute
    right
    hover-expand
    reduce
    v-model="sidebarActive"
    open
  >
    <!--    侧边栏头部-->
    <template #logo>
      <vs-icon
        :style="{
          color: !isDark ? 'rgba(5, 5, 5, 0.76)' : 'rgb(238,233,233)',
          fontSize: '40px',
        }"
        ><vsIconHierarchySquare2Bold
      /></vs-icon>
    </template>
    <!--    侧边栏身体-->
    <template :key="items.id" v-for="items in sidebarData.menu">
      <!--    没有子菜单-->
      <template v-if="!items.children">
        <vs-sidebar-item :id="items.id">
          <template #icon>
            <i :class="items.icon"></i>
          </template>
          {{ $t(items.title) }}
        </vs-sidebar-item>
      </template>
      <!--    有子菜单-->
      <template v-else>
        <vs-sidebar-group
          :class="isDark ? 'header-navbar-dark' : 'header-navbar'"
        >
          <template #header>
            <vs-sidebar-item arrow :id="items.id">
              <template #icon>
                <i :class="items.icon"></i>
              </template>
              {{ $t(items.title) }}
            </vs-sidebar-item>
          </template>
          <template :key="item.id" v-for="item in items.children">
            <vs-sidebar-item :id="item.id">
              <template #icon>
                <i :class="item.icon"></i>
              </template>
              {{ $t(item.title) }}
            </vs-sidebar-item>
          </template>
        </vs-sidebar-group>
      </template>
    </template>
    <!--    侧边栏尾部-->
    <template #footer>
      <vs-row :style="{ width: '100%', justifyContent: 'space-between' }">
        <vs-avatar badge-color="danger" badge-position="top-right">
          <vs-icon><vsIconMessages /></vs-icon>
          <template #badge> 26 </template>
        </vs-avatar>
        <vs-avatar>
          <img src="@/assets/img/avatars/avatar-2.jpg" alt="" />
        </vs-avatar>
      </vs-row>
    </template>
  </vs-sidebar>
</template>

<script lang="ts" setup>
// 夜间模式仓
import { useDark } from "@vueuse/core";
import { defineProps, ref } from "vue";
const isDark = useDark();
const sidebarActive = ref("index");
defineProps<{
  sidebarData: object;
  sidebarActive: string;
}>();
</script>

<script lang="ts">
export default {
  name: "TcVsSidebar",
};
</script>

<style scoped></style>
