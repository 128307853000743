<template>
  <vs-button
    class="tc-vs-button-1"
    type="flat"
    :color="color ? color : 'discord'"
    :style="{ width: width + 'px', height: height + 'px' }"
    icon
  >
    <h1><i :class="'bx ' + icon" />{{ title }}</h1>
    <p>{{ content }}</p>
  </vs-button>
</template>

<script lang="ts" setup>
import { defineProps, ref } from "vue";
import type { Color } from "vuesax-alpha";
defineProps({
  color: ref<Color>(),
  content: String,
  height: String,
  icon: String,
  title: String,
  width: String,
});
</script>

<script lang="ts">
export default {
  name: "TcVsButton1",
};
</script>

<style scoped>
.tc-vs-button-1 div.vs-button__content h1 {
  font-size: 18px !important;
}
</style>
