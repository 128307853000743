<template>
  <router-view />
</template>

<script lang="ts" setup></script>

<style lang="postcss">
@import "vuesax-alpha/theme-chalk/dark/css-vars.css";
@import "vuesax-alpha/theme-chalk/index.css";
@import "vuesax-alpha/dist/index.css";
/*vuesax补偿*/
/*弹出层全屏*/
div.vs-dialog {
  min-width: 100vw !important;
}
/*选择器选项修复*/
div.vs-select__content {
  height: 100% !important;
  max-height: 200px !important;
}
div.vs-select__options {
  width: 100% !important;
}
div.vs-select__label {
  top: -28px !important;
}
/*侧边栏颜色一致*/
div.vs-sidebar-item__icon {
  backdrop-filter: blur(60px) !important;
  background-color: #e7e7e7 !important;
}
.dark div.vs-sidebar-item__icon {
  background-color: #333333 !important;
}
div.vs-sidebar-group__content button {
  backdrop-filter: blur(60px) !important;
  background-color: #e7e7e7 !important;
}
.dark div.vs-sidebar-group__content button {
  background-color: #333333 !important;
}
/*菜单按钮样式, 两行*/
.tc-vs-button-1 div.vs-button__content {
  display: flex !important;
  flex-direction: column !important;
  align-items: start;
  justify-content: space-evenly !important;
  height: 100% !important;
  max-width: 80vw;
}
</style>
