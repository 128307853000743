import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import ArcoVue from "@arco-design/web-vue";
import "@arco-design/web-vue/dist/arco.css";
import PrimeVue from "primevue/config";
import "primevue/resources/primevue.min.css"; // core
import "primevue/resources/themes/lara-light-indigo/theme.css"; // theme
import "primeicons/primeicons.css"; // icons
import "primeflex/primeflex.css"; // PrimeFlex
import Button from "primevue/button";
import MegaMenu from "primevue/megamenu";
import vuesax from "vuesax-alpha";
import * as VuesaxAlphaIconsVue from "@vuesax-alpha/icons-vue";
import LanguageEn from "@/languages/en.json";
import LanguageZh from "@/languages/zh.json";
import "tailwindcss/tailwind.css";

// 创建 i18n 实例
const i18n = createI18n({
  legacy: false, // 为 true 时，将启用 Vue 2 的 i18n API
  locale: "zh", // 设置默认语言
  messages: {
    en: LanguageEn, // 引入英语翻译文件
    zh: LanguageZh, // 引入法语翻译文件
    // ...
  },
});
const app = createApp(App);
for (const [key, component] of Object.entries(VuesaxAlphaIconsVue)) {
  app.component(`VsIcon${key}`, <never>component);
}
app.use(store);
app.use(router);
app.use(PrimeVue);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Button", Button);
app.component("MegaMenu", MegaMenu);
app.use(i18n);
app.use(ArcoVue);
app.use(vuesax);
app.mount("#app");
