import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "首页导航",
    component: HomeView,
    children: [
      {
        path: "/",
        name: "首页导航",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
      },
      {
        path: "/login",
        name: "登录注册",
        component: () => import("../views/AboutView.vue"),
      },
      {
        path: "/market",
        name: "艺术市场",
        component: () => import("../views/market/marketIndex.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
