<template>
  <div
    class="center page-market"
    :class="isDark ? 'page-navbar-dark' : 'page-navbar-light'"
  >
    <!--    导航栏-->
    <vs-navbar
      square
      v-model="navbarMenuActive"
      :class="isDark ? 'header-navbar-dark' : 'header-navbar'"
      target-scroll="#padding-scroll-content"
      left-collapsed
      right-collapsed
    >
      <template #left>
        <i
          @click="visitDialogLogin = !visitDialogLogin"
          class="bx bxl-apple"
          :style="{
            color: !isDark ? 'rgba(5, 5, 5, 0.76)' : 'rgb(238,233,233)',
            fontSize: '48px',
          }"
        ></i>
        <h3>{{ $t("index-navbar-info") }}</h3>
      </template>
      <vs-navbar-item
        @click="navbarMenuActive = 'index'"
        :active="navbarMenuActive === 'index'"
        to="/"
      >
        <vs-icon :style="{ background: 'var(--vs-danger)' }"
          ><vsIconHeartBold
        /></vs-icon>
        {{ $t("index-navbar-item-index") }}
      </vs-navbar-item>
      <vs-navbar-item
        @click="navbarMenuActive = 'market'"
        :active="navbarMenuActive === 'market'"
        to="/market"
      >
        <vs-icon><vsIconBoxBold /></vs-icon>
        {{ $t("index-navbar-item-market") }}
      </vs-navbar-item>
      <vs-navbar-item
        @click="navbarMenuActive = 'dream'"
        :active="navbarMenuActive === 'dream'"
        :link="{ path: '/market', text: '艺术市场' }"
      >
        <vs-icon><vsIconTerminalBold /></vs-icon>
        {{ $t("index-navbar-item-demand") }}
      </vs-navbar-item>
      <vs-navbar-item
        @click="navbarMenuActive = 'discuss'"
        :active="navbarMenuActive === 'discuss'"
      >
        <vs-icon><vsIconAlarmBold /></vs-icon>
        {{ $t("index-navbar-item-chat") }}
      </vs-navbar-item>
      <vs-navbar-item
        @click="navbarMenuActive = 'exhibition'"
        :active="navbarMenuActive === 'exhibition'"
        id="license"
      >
        <vs-icon :style="{ background: 'var(--vs-danger)' }"
          ><vsIconHeartBold
        /></vs-icon>
        {{ $t("index-navbar-item-show") }}
      </vs-navbar-item>
      <template #right>
        <vs-tooltip type="shadow" interactivity>
          <vs-avatar
            class="header-navbar-button"
            size="40"
            badge
            badge-color="discord"
            history
            history-gradient
            shape="circle"
          >
            <img src="@/assets/img/avatars/avatar-1.jpg" alt="" />
            <template #badge
              ><span>{{ $t("common-user-status") }}</span></template
            >
          </vs-avatar>
          <template #content>
            <div class="content-tooltip">
              <div class="body">
                <div class="text">
                  {{ $t("index-sidebar-item-develop-demandList") }}
                  <span> 89 </span>
                </div>
                <vs-avatar
                  shape="circle"
                  size="80"
                  @click="activeTooltip1 = !activeTooltip1"
                >
                  <img src="@/assets/img/avatars/avatar-1.jpg" alt="" />
                </vs-avatar>
                <div class="text">
                  {{ $t("index-sidebar-item-develop-artList") }}
                  <span> 128 </span>
                </div>
              </div>
              <h1 style="font-weight: bolder; padding: 6px">
                {{
                  CommonTool.getItem("tokenInfo")["iss"] ||
                  $t("common-login-default")
                }}
              </h1>
              <footer>
                <vs-button color="danger" shape="circle" icon type="border">
                  <i class="bx bxs-heart"></i>
                </vs-button>
                <vs-button color="danger" shape="circle">
                  {{ $t("index-sidebar-item-user") }}
                </vs-button>
                <vs-button color="danger" shape="circle" icon type="border">
                  <i class="bx bx-like" />
                </vs-button>
              </footer>
            </div>
          </template>
        </vs-tooltip>
        <vs-avatar
          size="40"
          class="header-navbar-button"
          v-model="$i18n.locale"
          @click="$i18n.locale = $i18n.locale === 'zh' ? 'en' : 'zh'"
        >
          <i
            style="color: goldenrod"
            :class="
              $i18n.locale === 'en' ? 'bx bxs-dollar-circle' : 'bx bx-yen'
            "
          ></i>
        </vs-avatar>
        <vs-avatar
          size="40"
          class="header-navbar-button"
          v-model="isDark"
          @click="toggleDark()"
        >
          <i
            style="color: goldenrod"
            :class="isDark ? 'bx bxs-sun' : 'bx bxs-moon'"
          ></i>
        </vs-avatar>
        <vs-avatar
          size="40"
          class="header-navbar-button"
          badge-color="danger"
          badge-position="top-right"
        >
          <vs-icon><vsIconBell /></vs-icon>
          <template #badge> 8 </template>
        </vs-avatar>
        <vs-avatar
          size="40"
          @click="visitDialogAvatar = !visitDialogAvatar"
          class="header-navbar-button"
          badge-color="danger"
          badge-position="top-right"
        >
          <vs-icon><vsIconCommand /></vs-icon>
        </vs-avatar>
        <!--        <vs-button color="linkedin" type="border" upload flat>登入</vs-button>-->
        <!--        <vs-button color="linkedin" type="flat">注册</vs-button>-->
      </template>
    </vs-navbar>
    <!--      内容-->
    <div class="market-content" id="padding-scroll-content">
      <router-view></router-view>
      <!--    页脚-->
      <footer class="market-footer" @click="MsgServer.chat()">
        <div class="market-footer-left">
          <p class="market-footer-title">关于我们</p>
          <p>一个不正经的科技公司</p>
        </div>
        <div class="market-footer-center">
          <p class="market-footer-title">创始人</p>
          <vs-avatar-group max="5">
            <vs-avatar :key="index" v-for="index in 8">
              <img src="@/assets/author.png" alt="boss" />
            </vs-avatar>
          </vs-avatar-group>
        </div>
        <div class="market-footer-center">
          <p class="market-footer-title">产品理念</p>
          <p>更符合现代的数字交易</p>
        </div>
        <div class="market-footer-center">
          <p class="market-footer-title">最新动态</p>
          <p>有个锤子</p>
        </div>
        <div class="market-footer-right">
          <p class="market-footer-title">服务相关</p>
          <p>买了么卖了么贷了么</p>
        </div>
        <div class="market-footer-bottom">
          <p :style="{ display: 'flex' }">
            湖南省湘潭市雨湖区昭潭乡韶山西路389号新金都商业广场1401003-A14387号
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <img
              src="https://thatcoder.cn/img/beianIcon.png"
              width="20px"
              height="20px"
            /><a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43030202001297"
            >
              湘公网安备 43030202001297号</a
            >
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >湘ICP备2023019799号-1</a
            >
          </p>
        </div>
      </footer>
    </div>
    <!--      侧边栏-->
    <template v-if="!['index', ''].includes(navbarMenuActive)">
      <tc-vs-sidebar
        :sidebarData="sidebarMenuData"
        :sidebar-active="sidebarMenuActive"
      />
    </template>
  </div>
  <!--  下面全是弹出层  -->
  <!--  点击头像菜单-->
  <vs-dialog
    v-model="visitDialogAvatar"
    scroll
    lock-scroll
    not-close
    auto-width
    overlay-blur
  >
    <template #header>
      <h1 style="font-size: 20px; font-weight: bolder">菜单</h1>
    </template>
    <div class="con-content-scroll">
      <tc-vs-button1
        icon="bxs-moon"
        title="主题设置"
        content="按喜好订制页面"
        color="discord"
        width="168"
        height="80"
      />
      <tc-vs-button1
        icon="bxs-moon"
        title="主题"
        content="按喜好订制页面"
        color="discord"
        width="168"
        height="80"
      />
      <tc-vs-button1
        icon="bxs-moon"
        title="主题"
        content="按喜好订制页面"
        color="discord"
        width="168"
        height="80"
      /><tc-vs-button1
        icon="bxs-moon"
        title="主题"
        content="按喜好订制页面"
        color="discord"
        width="168"
        height="80"
      />
      <tc-vs-button1
        icon="bxs-moon"
        title="主题"
        content="按喜好订制页面"
        color="discord"
        width="168"
        height="80"
      />
    </div>
  </vs-dialog>
  <!--  非首页登入注册-->
  <vs-dialog v-model="visitDialogLogin" overlay-blur>
    <template #header>
      <h4 class="not-margin">Welcome to <b>Art Stack</b></h4>
    </template>

    <div class="con-form">
      <vs-input v-model="conFormValue.email" placeholder="Email">
        <template #icon> @ </template>
      </vs-input>
      <vs-input
        v-model="conFormValue.password"
        type="password"
        placeholder="Password"
      >
        <template #icon>
          <i class="bx bxs-lock" />
        </template>
      </vs-input>
      <div class="flex">
        <vs-checkbox v-model="conFormValue.rm">Remember me</vs-checkbox>
        <a href="#">Forgot Password?</a>
      </div>
    </div>

    <template #footer>
      <div class="footer-dialog">
        <vs-button block> Sign In </vs-button>

        <div class="new">New Here? <a href="#">Create New Account</a></div>
      </div>
    </template>
  </vs-dialog>
</template>

<script setup lang="ts">
// iconfont: //at.alicdn.com/t/c/font_4240721_y82hcipl5cf.js
import { ref } from "vue";
import TcVsButton1 from "@/components/vueSax/TcVsButton1.vue";
import TcVsSidebar from "@/components/vueSax/TcVsSidebar.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  VsAvatar,
  VsAvatarGroup,
  VsButton,
  VsCheckbox,
  VsIcon,
  VsInput,
  VsDialog,
  VsNavbar,
  VsNavbarItem,
  VsTooltip,
} from "vuesax-alpha";

// 夜间模式仓
import { useDark, useToggle } from "@vueuse/core";
import MsgServer from "@/utils/MsgServer";
import CommonTool from "../utils/CommonTools";
const isDark = useDark();
const toggleDark = () => {
  const toggleDark2 = useToggle(isDark);
  toggleDark2();
  // openNotification("top-center", "warn");
};

// 可见性控制仓
const visitDialogAvatar = ref(false);
const visitDialogLogin = ref(false);

// 属性变更仓
const activeTooltip1 = ref(false);

// 数据仓
const conFormValue = ref({
  email: "",
  password: "",
  rm: false,
});

// 菜单数据
let navbarMenuActive = ref(window.location.pathname.split("/")[1]);
let sidebarMenuActive = ref("user");
let sidebarMenuData = ref({
  menu: [
    {
      id: "user",
      path: "/user",
      title: "index-sidebar-item-user",
      icon: "bx bxs-user",
    },
    {
      id: "demands",
      title: "index-sidebar-item-demand",
      icon: "bx bxs-box",
      children: [
        {
          id: "demanderAdd",
          path: "/demander/add",
          title: "index-sidebar-item-demand-add",
          icon: "bx bx-add-to-queue",
        },
        {
          id: "demanderList",
          path: "/demander/list",
          title: "index-sidebar-item-demand-list",
          icon: "bx bx-list-ul",
        },
        {
          id: "demanderArb",
          path: "/demander/arbitration",
          title: "index-sidebar-item-demand-help",
          icon: "bx bxs-check-shield",
        },
      ],
    },
    {
      id: "developer",
      title: "index-sidebar-item-develop",
      icon: "bx bxs-terminal",
      children: [
        {
          id: "developerAdd",
          path: "/developer/add",
          title: "index-sidebar-item-develop-demandList",
          icon: "bx bx-list-check",
        },
        {
          id: "developerList",
          path: "/developer/list",
          title: "index-sidebar-item-develop-artList",
          icon: "bx bxs-spa",
        },
        {
          id: "developerArb",
          path: "/developer/arbitration",
          title: "index-sidebar-item-develop-help",
          icon: "bx bxs-check-shield",
        },
      ],
    },
  ],
});
//载入执行
</script>

<style scoped lang="postcss">
.page-market {
  padding: 0;
  position: relative;
}
.page-navbar-light {
  background: url("@/assets/cool-background-light.png") no-repeat;
  background-size: cover;
}
.page-navbar-dark {
  background: url("@/assets/cool-background-dark.png") no-repeat;
  background-size: cover;
}
.header-navbar {
  background-color: #e7e7e7d4;
  backdrop-filter: blur(60px);
}
.header-navbar-dark {
  background-color: #333333d4;
  backdrop-filter: blur(60px);
}
.header-navbar-button {
  /*margin-left: 5px;*/
  /*margin-right: 5px;*/
  margin: 5px;
}
/* 内容在中间(内容宽度适中, 两边可留白), 内容可能很多, 可以把页脚挤下去, 内容一般为瀑布流 */
.market-content {
  height: 100vh;
  position: relative;
  width: 100%;
  overflow: auto;
  max-width: 90vw;
  margin-left: 5vw;
  padding-top: 68px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  backdrop-filter: blur(0.15rem);
}
.market-content::-webkit-scrollbar {
  display: none;
}

/* 让页脚能被挤下去, 内容高度不够则页脚固定在底部 */
.market-footer {
  position: relative;
  bottom: 0;
  font-size: 14px;
  flex-shrink: 0; /* 防止内容不够高时页脚缩小 */
  display: flex; /* 使用弹性布局 */
  flex-wrap: wrap; /* 允许内容换行 */
  justify-content: space-between; /* 左中右对齐 */
  align-items: center; /* 垂直居中对齐 */
  padding: 10px; /* 可根据需要调整页脚内边距 */
  background: var(--vs-navbar-background);
}

/**
 * 页脚左侧, 里面的标签每个占一行且居中
 */
.market-footer-left {
  flex: 1; /* 自动占据剩余空间 */
  text-align: center; /* 文字居中对齐 */
}

/**
 * 页脚中间, 里面的标签每个占一行且居中
 */
.market-footer-center {
  flex: 1; /* 自动占据剩余空间 */
  text-align: center; /* 文字居中对齐 */
}

/**
 * 页脚右侧, 里面的标签每个占一行且居中
 */
.market-footer-right {
  flex: 1; /* 自动占据剩余空间 */
  text-align: center; /* 文字居中对齐 */
}
.market-footer-title {
  font-size: 18px;
}

/**
 * 页脚底部, 里面的标签每个占一行且居中, 这里主要是地址和备案号, 很长的
 */
.market-footer-bottom {
  width: 100%; /* 使其独占一行 */
  text-align: center; /* 文字居中对齐 */
  font-size: 12px; /* 可根据需要调整字体大小 */
  margin-top: 10px; /* 可根据需要调整顶部边距 */
  display: flex; /* 使用弹性布局 */
  flex-direction: column; /* 垂直布局，每个部分另起一行 */
  align-items: center; /* 垂直居中对齐 */
}

/* 侧边栏 */
.market-sidebar {
  position: fixed !important;
  top: 15vh;
  right: 0;
  max-height: 70vh;
  z-index: 10;
}

/*菜单弹出层布局*/
.con-content-scroll {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

/*vuesax补偿*/
div.vs-row > .vs-col {
  display: flex !important;
  justify-content: center !important;
}

/*头像菜单*/
.content-tooltip .body {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.content-tooltip .body .vs-avatar-content {
  margin-top: -30px;
  border: 3px solid rgb(255, 71, 87);
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
}

.content-tooltip .body .text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.55rem;
  padding: 10px;
  font-weight: normal;
}

.content-tooltip .body .text span {
  font-weight: bold;
  font-size: 0.7rem;
}

.content-tooltip footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-tooltip h4 {
  padding: 8px;
  margin: 0;
  text-align: left;
}

.content-tooltip p {
  text-align: left;
  margin: 0;
  line-height: 1rem;
  padding: 0 0 5px 8px;
}
</style>
