import VsNotification from "vuesax-alpha/es/components/notification/index";
import VsLoading from "vuesax-alpha/es/components/loading/index";
import VsInfiniteScroll from "vuesax-alpha/es/components/infinite-scroll/index";
const tcPlugin = {
  VsNotification: VsNotification,
  VsLoading: VsLoading,
  VsInfiniteScroll: VsInfiniteScroll,
};

export default tcPlugin;
