const namespace = "ArtMarket";
/**
 * 全局工具类
 */
const CommonTool = {
  /**
   * 设置命名空间的 localStorage item
   * @param key 对象键
   * @param value 对象值
   */
  setItem: (key: string, value: any) => {
    const storage = CommonTool.getStorage();
    storage[key] = value;
    window.localStorage.setItem(namespace, JSON.stringify(storage));
  },
  /**
   * 获取命名空间的 localStorage item
   * @param key 对象键
   */
  getItem: (key: string) => {
    return CommonTool.getStorage()[key];
  },
  /**
   * 获取全部命名空间的 localStorage item
   */
  getStorage: () => {
    return JSON.parse(window.localStorage.getItem(namespace) || "{}");
  },
  /**
   * 清空命名空间的 localStorage item
   * @param key 对象键
   */
  cleanItem: (key: string) => {
    const storage = CommonTool.getStorage();
    delete storage[key];
    window.localStorage.setItem(namespace, JSON.stringify(storage));
  },
  /**
   * 清空命名空间的 localStorage
   */
  clearStorage: () => {
    window.localStorage.removeItem(namespace);
  },
  /**
   * 清空浏览器全部 localStorage
   */
  clearAllStorage: () => {
    window.localStorage.clear();
  },
  /**
   * 解析 JWT Payload 官方字段
   * @param jwt JWT主体内容
   */
  decodeJwt: (jwt: string) => {
    return JSON.parse(
      decodeURIComponent(
        encodeURI(
          window.atob(jwt.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"))
        )
      )
    );
  },
};

export default CommonTool;
